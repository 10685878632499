import Vue from "vue";
import VueRouter from "vue-router";
// const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue');
import Home from "../views/Home.vue";
// import Help from "../views/Help.vue";
// const Help = () => import(/* webpackChunkName: "help" */ '../views/Help.vue');
// import Terms from "../views/Terms.vue";
// const Terms = () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue');
// import PrivateChef from "../views/PrivateChef.vue";
const ProfileView = () => import(/* webpackChunkName: "Profile" */ '../views/Profile.vue');
const PrivateChef = () => import(/* webpackChunkName: "PrivateChef" */ '../views/PrivateChef.vue');
// import Privacy from "../views/Privacy.vue";
const Privacy = () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue');
// import Search from "../views/Search.vue";
// const Search = () => import(/* webpackChunkName: "search" */ '../views/Search.vue');
// import SearchOld from "../views/SearchOld.vue";
// import Book from "../views/Book.vue";
const Book = () => import(/* webpackChunkName: "book" */ '../views/Book.vue');
// import Checkout from "../views/Checkout.vue";
const Checkout = () => import(/* webpackChunkName: "book" */ '../views/Checkout.vue');
// import Dashboard from "../views/Dashboard";
const Dashboard = () => import(/* webpackChunkName: "account" */ '../views/Dashboard');
// import Account from "../views/Account";
const Account = () => import(/* webpackChunkName: "account" */ '../views/Account');
// import Admin from "../views/Admin";
const Admin = () => import(/* webpackChunkName: "admin" */ '../views/Admin');
// import OverviewAdmin from '../components/admin/Overview';
const OverviewAdmin = () => import(/* webpackChunkName: "admin" */ '../components/admin/Overview');
// import ChefsAdmin from '../components/admin/Chefs';
const ChefsAdmin = () => import(/* webpackChunkName: "admin" */ '../components/admin/Chefs');
// import ClientsAdmin from '../components/admin/Clients';
const ClientsAdmin = () => import(/* webpackChunkName: "admin" */ '../components/admin/Clients');
// import BookingsAdmin from '../components/common/Bookings';
const BookingsAdmin = () => import(/* webpackChunkName: "admin" */ '../components/common/Bookings');
// import Parameters from '../components/admin/Parameters';
const Parameters = () => import(/* webpackChunkName: "admin" */ '../components/admin/Parameters');
// import Details from '../components/common/Details';
const Details = () => import(/* webpackChunkName: "dashboard" */ '../components/common/Details');
// import Company from '../components/dashboard/Company';
const Company = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Company');
// import Profile from '../components/dashboard/Profile';
const Profile = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Profile');
// import Documents from '../components/dashboard/Documents';
const Documents = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Documents');
// import Photos from '../components/dashboard/Photos';
const Photos = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Photos');
// import Menus from '../components/dashboard/Menus';
const Menus = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Menus');
// import Overview from '../components/dashboard/Overview';
const Overview = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Overview');
// import OverviewAccount from '../components/account/OverviewAccount';
const OverviewAccount = () => import(/* webpackChunkName: "dashboard" */ '../components/account/OverviewAccount');
// import Bank from '../components/dashboard/Bank';
const Bank = () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Bank');
const SignupChef = () => import(/* webpackChunkName: "SignupChef" */ '../views/SignupChef.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: "/search",
    name: "Search",
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: { title: 'Private chef and catering service in Australia - Search',
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Search all the private chefs in your area. A private chef and catering service that brings restaurant quality in your home.'
        },
      ]
    },
  },
  // {
  //   path: "/searchOld",
  //   name: "SearchOld",
  //   component: SearchOld
  // },
  {
    path: "/book",
    name: "Book",
    component: Book,
    meta: { title: 'Book a Private chef and catering service in Australia' }
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: { title: 'Checkout a Private chef and catering service in Australia' }
  },
  {
    path: "/help",
    name: "Help",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      title: 'Private chef and catering service in Australia - Help',
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Help or frequently asked questions for the platform Kootto, a private chef and catering service that brings restaurant quality in your home.'
        },
      ]
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      title: 'Private chef and catering service in Australia - Terms',
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Terms and conditions for the platform Kootto, a private chef and catering service that brings restaurant quality in your home.'
        },
      ]
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: { title: 'Private chef and catering service in Australia - Privacy',
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Privacy for the platform Kootto, a private chef and catering service that brings restaurant quality in your home.'
        },
      ]}
  },
  {
    path: "/admin/",
    name: "Admin",
    component: Admin,
    children:[
      { path: 'overview',
        name: "OverviewAdmin",
        component: OverviewAdmin,
        meta: { title: 'Kootto - Admin - Overview' }
      },
      { path: 'chefs',
        name: "Chefs",
        component: ChefsAdmin,
        meta: { title: 'Kootto - Admin - Chefs' }
      },
      { path: 'clients',
        name: "Clients",
        component: ClientsAdmin,
        meta: { title: 'Kootto - Admin - Clients' }
      },
      { path: 'bookings',
        name: "Bookings",
        component: BookingsAdmin,
        meta: { title: 'Kootto - Admin - Bookings' }
      },
      { path: 'parameters',
        name: "Parameters",
        component: Parameters,
        meta: { title: 'Kootto - Admin - Parameters' }
      },
      { path: 'potentialCustomers',
        name: "PotentialCustomers",
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/PotentialCustomers.vue'),
        meta: { title: 'Kootto - Admin - Potential Customers' }
      },
    ]
  },
  {
    path: "/:id?/dashboard/",
    name: "Dashboard",
    component: Dashboard,
    children:[
      { path: 'overview',
        name: "Overview",
        component: Overview,
        meta: { title: 'Kootto - Chef - Dashboard' }
      },
      { path: 'bookings',
        name: "BookingsDashboard",
        component: BookingsAdmin,
        meta: { title: 'Kootto - Chef - Bookings' }
      },
      {path: "details",
        name: "Details",
        component: Details,
        meta: { title: 'Kootto - Chef - Details' }
      },
      {path: "profile",
        name: "Profile",
        component: Profile,
        meta: { title: 'Kootto - Chef - Profile' }
      },
      {path: "company",
        name: "Company",
        component: Company,
        meta: { title: 'Kootto - Chef - Company' }
      },
      {path: "bank",
        name: "Bank",
        component: Bank,
        meta: { title: 'Kootto - Chef - Bank' }
      },
      {path: "documents",
        name: "Documents",
        component: Documents,
        meta: { title: 'Kootto - Chef - Documents' }
      },
      {path: "photos",
        name: "Photos",
        component: Photos,
        meta: { title: 'Kootto - Chef - Photos' }
      },
      {path: "menus",
        name: "Menus",
        component: Menus,
        meta: { title: 'Kootto - Chef - Menus' }
      }
    ]
  },
  {
    path: "/:id?/account/",
    name: "Account",
    component: Account,
    children:[
      { path: 'overview',
        name: "OverviewAccount",
        component: OverviewAccount,
        meta: { title: 'Kootto - Client - Overview' }
      },
      { path: 'bookings',
        name: "BookingsAccount",
        component: BookingsAdmin,
        meta: { title: 'Kootto - Client - Bookings' }
      },
      {path: "details",
        name: "Details",
        component: Details,
        meta: { title: 'Kootto - Client - Details' }
      },
    ]
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupChef,
    meta: {
      title: 'Signup as a private chef in Australia' ,
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Join our private chef platform today and start taking your career to the next level. We can\'t wait to see what delicious creations you\'ll bring to the table!'
        },
      ]
    }
  },
  {
    path: "/private-chef/city/:city?/",
    name: "Private Chef",
    component: PrivateChef,
    meta: {
      title: 'Find a private chef and catering service in Australia' ,
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Private chef service that brings top restaurant quality to your home. No grocery shopping, cooking, or cleaning up, so you can fully enjoy your party.'
        },
      ]
    }
  },
  {
    path: "/personal-chef/city/:city?/",
    name: "Personal Chef",
    component: PrivateChef,
    meta: {
      title: 'Find a private chef and catering service in Australia' ,
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Private chef service that brings top restaurant quality to your home. No grocery shopping, cooking, or cleaning up, so you can fully enjoy your party.'
        },
      ]
    }
  },
  {
    path: "/personal-chef/test/",
    name: "Personal Chef",
    component: PrivateChef,
  },
  {
    path: "/profile/:name?/",
    name: "Profile",
    component: ProfileView,
    meta: { title: 'Kootto - ' }
  },
  {
    path: "/contactUs",
    name: "Home",
    component: Home,
  },
  // /:pathMatch(.*)
  // { path: '*', redirect: '/' },
  {
    path: "/:pathMatch(.*)",
    name: "Home",
    component: Home,
    meta: { title: 'Private chef and catering service in Australia - Kootto',
      metaTags: [
        {
          name: 'description',
          property: 'og:description',
          content: 'Private chef service that brings top restaurant quality to your home. No grocery shopping, cooking, or cleaning up, so you can fully enjoy your party.'
        },
      ]
    }
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/',
  scrollBehavior(to){
    if (to.hash) {
      return {
        selector: to.hash
      }
    }else{
      document.getElementById('app').scrollIntoView();
    }
  }
});

export default router;
