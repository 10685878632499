import Vue from "vue";
import Vuetify from "vuetify/lib";
// import Vuetify from "vuetify";
// import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

const opts = {
    // dark:false,
    theme: {
        // dark: false,
        themes: {
            light: {
                // primary: "#4682b4",
                // primary: "#FF8F00",
                // textPrimary: "#FF8F00",
                // primary: "#e5b700", //candidate
                // primary: "#ffb300",//choice
                // textPrimary: "#ffb300",//choice
                // primary: "#f2c417", //guzman color
                // textPrimary: "#f2c417",
                // primary: "#43A047",

                primary:"#00695C",
                textPrimary: "#00695C",
                secondary: "#b0bec5",
                accent: "#8c9eff",
                error: "#b71c1c",
                dark_primary: '#ffb300',
            },
            dark: {

                primary: "#ffb300",
                textPrimary: "#ffb300",
                secondary: "#b0bec5",
                accent: "#8c9eff",
                light_primary: "#00695C",
            }
        },
    }
}

export default new Vuetify(opts)
